/* This example requires Tailwind CSS v2.0+ */

import * as React from "react";
import { v4 as uuidv4 } from "uuid";

export default function References() {
  const referencesList = [
    { name: "Big Sean", description: "" },
    { name: "Post Malone" },
    { name: "QC" },
    { name: "Russ" },
    { name: "Miguel" },
    { name: "Bryson Tiller" },
    { name: "The BET's" },
    { name: "...and many more" },
  ];

  return (
    <div className="bg-white">
      <p className="text-lg">
        Kunden durch unserere Partnerschaft mit{" "}
        <a
          href="https://joe.studio/"
          className="text-yellow-500 underline"
          target="_blank"
        >
          joe hellow
        </a>
        .
      </p>
      <ul className="mt-6 list-disc pl-6">
        {referencesList.map((reference) => (
          <li key={uuidv4()} className="">
            <h3 className="text-xl">{reference.name}</h3>
          </li>
        ))}
      </ul>
    </div>
  );
}
